import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { texts } from "../assets/constants";
import image from "../assets/welcome_image.png";

const WelcomeNew = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Stack
        gap={2}
        sx={{
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box sx={{ maxWidth: "350px" }}>
          <img src={image} alt="" width="100%" />
        </Box>
        <Typography variant="body1" sx={{ maxWidth: 200, lineHeight: 1 }}>
          {texts.welcomeNew.text1}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
            backgroundColor: "#262624",
            borderRadius: "40px",
            padding: "5px 20px",
          }}
        >
          {texts.welcomeNew.text2}
        </Typography>
        <Typography variant="h2">{texts.welcomeNew.text3}</Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {[1, 2, 3].map((i) => (
            <Box
              key={i}
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
            />
          ))}
        </Box>
        <Stack>
          <Typography variant="h4">{texts.welcomeNew.text4}</Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: (sx) => sx.breakpoints.values.sm,
            }}
          >
            {texts.welcomeNew.text5}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h3">{texts.welcomeNew.text6}</Typography>
          <Typography
            variant="h2"
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
              color: (theme) => theme.palette.primary.contrastText,
              borderRadius: "40px",
              padding: "4px 30px",
              zIndex: 1,
            }}
          >
            {texts.welcomeNew.text7}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default WelcomeNew;
