import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import logo from "../assets/logo_200.png";
import logoSmall from "../assets/logo_square.png";
import React from "react";

const Logo = () => {
  return (
    <Link to="/">
      <Box sx={{ maxWidth: "200px", display: { xs: "none", md: "flex" } }}>
        <img src={logo} alt="logo" style={{ width: "100%" }} />
      </Box>
      <Box sx={{ maxWidth: "75px", display: { sm: "flex", md: "none" } }}>
        <img src={logoSmall} alt="logo" style={{ width: "100%" }} />
      </Box>
    </Link>
  );
};

export default Logo;
