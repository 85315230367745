import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import { texts } from "../assets/constants";

const addLineBreak = (string: string) => {
  return string.split("\n").map((subStr) => {
    return (
      <span key={subStr}>
        {subStr}
        <br />
      </span>
    );
  });
};

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Toolbar />
      <Typography variant="h4" mt={4}>
        {texts.privacyPolicy.title}
      </Typography>
      <ol>
        {texts.privacyPolicy.text.map((block, idx1) => (
          <Stack key={`block${idx1}`}>
            <Typography variant="h5" mt={4} component="li">
              {block.title}
            </Typography>
            <ol style={{ listStyleType: "hebrew", paddingInlineStart: 10 }}>
              {block.children?.map((child, idx2) => (
                <Stack key={`block${idx1}child${idx2}`}>
                  <Typography component="li">
                    {addLineBreak(child.text)}
                  </Typography>
                  <ol style={{ paddingInlineStart: 10 }}>
                    {child.children?.map((subchild, idx3) => (
                      <Typography
                        key={`block${idx1}child${idx2}subchild${idx3}`}
                        component="li"
                        sx={{ color: "text.secondary" }}
                      >
                        {subchild}
                      </Typography>
                    ))}
                  </ol>
                </Stack>
              ))}
            </ol>
          </Stack>
        ))}
      </ol>
    </Container>
  );
};

export default PrivacyPolicy;
