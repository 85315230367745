import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: `"Ploni", "Rubik", "Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: "3rem",
      fontWeight: 900,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.8rem",
      fontWeight: 800,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1.4rem",
      fontWeight: 300,
      lineHeight: 1,
    },
    button: {
      fontSize: "1.4rem",
      fontWeight: 600,
    },
  },
  palette: {
    primary: { main: "#259C80", contrastText: "#fff" },
    secondary: {
      main: "#B81C6E",
    },
    background: { default: "#ddfff5" },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
