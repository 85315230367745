import { Link as RouterLink } from "react-router-dom";

import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { texts } from "../assets/constants";

const Footer = () => {
  return (
    <Paper sx={{ mt: "auto", bottom: 0 }}>
      <Container sx={{ py: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography>{texts.footer.copyright}</Typography>
          <Link
            component={RouterLink}
            to="/privacy"
            sx={{ color: "text.secondary", textDecoration: "none" }}
          >
            {texts.footer.privacy}
          </Link>
        </Stack>
      </Container>
    </Paper>
  );
};

export default Footer;
