import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DOWNLOAD_BUTTONS, texts } from "../assets/constants";

const DownloadPage = () => {
  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack gap={2}>
        <Typography variant="body1" color="text.secondary" textAlign="center">
          {texts.download.text}
        </Typography>
        {DOWNLOAD_BUTTONS.sort((a, b) => a.id - b.id).map((button) => (
          <Button
            variant="contained"
            color={button.color || "primary"}
            href={button.href}
            target="_blank"
            key={button.id}
          >
            {button.text}
          </Button>
        ))}
      </Stack>
    </Container>
  );
};

export default DownloadPage;
