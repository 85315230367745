import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { texts } from "../assets/constants";
import image from "../assets/logo_text_big_200.png";
import Link from "@mui/material/Link";

const Questions = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#F3F3F3",
        top: "40px",
        position: "relative",
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction="row"
          sx={{
            py: 8,
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              width: "350px",
              padding: "0 10px",
            }}
          >
            <Typography variant="h5">{texts.questions.text1}</Typography>
              <Typography variant="body1">{texts.questions.text6}</Typography>
            <Typography variant="body1">{texts.questions.text2}</Typography>
            <Typography
              variant="body1"
              sx={{
                "::before": {
                  content: '"+"',
                  color: (theme) => theme.palette.secondary.main,
                  paddingRight: "5px",
                  fontWeight: 900,
                },
              }}
            >
              {texts.questions.text3}
            </Typography>
          </Stack>
          <Box
            sx={{
              width: "2px",
              alignSelf: "stretch",
              backgroundColor: (theme) => theme.palette.primary.main,
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          />
          <Stack
            sx={{
              width: "350px",
              padding: "0 10px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 300,
                marginBottom: "10px",
                "::before": {
                  content: '""',
                  display: "block",
                  width: "100%",
                  height: "2px",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  marginTop: "10px",
                },
              }}
            >
              {texts.questions.text4}
            </Typography>
            <Typography variant="body1">{texts.questions.text5}</Typography>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                fontWeight: 500,
              }}
            >
              <Link
                href={`tel:${texts.questions.phone}`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                {texts.questions.phone}
              </Link>
            </Typography>
          </Stack>
          <Box sx={{ maxWidth: "200px" }}>
            <img src={image} alt="" width="100%" />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Questions;
